<template>
  <div>
    <div
      class="candidate-dashboard"
      v-if="
        currentUserRole == Role.customerAdmin ||
        currentUserRole == Role.customerRecruiter
      "
    >
      <CRow class="tile">
        <CCol
          sm="12"
          lg="3"
          v-for="({ id, label, value }, index) in getCandidateData"
          :key="index"
        >
          <div class="card">
            <div class="card-body d-flex align-items-center p-3">
              <div
                class="mr-3 p-3 icon-wrapper"
                :style="getTheme(id).text_color"
              >
                <i v-if="iconHide(id)" :class="getTheme(id).src" />
                <img v-else :src="getTheme(id).src" />
              </div>
              <div>
                <div class="text-value" :style="getTheme(id).text_color">
                  {{ value }}
                </div>
                <div class="text-muted font-weight-bold small">{{ label }}</div>
              </div>
            </div>
          </div>
        </CCol>
      </CRow>
    </div>
    <div class="tile" v-else>
      <Tile
        :jobTotalCount="getJobTotalCount"
        :shortlistCount="getDashboardMetrics.SHORTLIST"
        :interviewCount="getDashboardMetrics.INTERVIEW"
        :recruitCount="getTotalCount"
      />
    </div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <CRow class="card-header-title d-flex align-items-center pl-3">
              <h5 class="text-primary">Application / Process Status</h5>
            </CRow>
          </CCardHeader>
          <CCardBody class="d-flex justify-content-center align-items-center">
            <CustomPieChart
              class="col-12"
              :PieChartData="getApplicationStatusCount"
              :PieChartLegendData="getApplicationStatusCount"
              :isFromJobStatus="true"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <CRow class="card-header-title d-flex align-items-center pl-3">
              <h5 class="text-primary">Job Status</h5>
            </CRow>
          </CCardHeader>
          <CCardBody class="d-flex justify-content-center align-items-center">
            <CustomPieChart
              class="col-12"
              :PieChartData="getJobStatusCount"
              :PieChartLegendData="getJobStatusCount"
              :isFromJobStatus="true"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import Vue from "vue";
import Tile from "@/containers/Dashboard/RecruiterDashBoard/Tile";
import ScratchPad from "@/containers/Dashboard/RecruiterDashBoard/ScratchPad";
import CalendarPad from "@/containers/Dashboard/RecruiterDashBoard/CalendarPad";
import CandidateJobAlert from "@/containers/Dashboard/RecruiterDashBoard/Alert1-CandidateJobStatus";
import RAGAlert from "@/containers/Dashboard/RecruiterDashBoard/Alert2-RAG";
import CandidateCommunication from "@/containers/Dashboard/RecruiterDashBoard/CandidateCommunication";
import { FACILITY_CANDIDATE_URL } from "@/helpers/helper";
import { mapActions, mapGetters } from "vuex";
import { Role, getScope } from "@/helpers/helper";
import CustomPieChart from "@/components/Dashboard/CustomPieChart";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";

export default {
  components: {
    Tile,
    ScratchPad,
    CalendarPad,
    CandidateJobAlert,
    RAGAlert,
    CandidateCommunication,
    CustomPieChart,
  },
  data() {
    return {
      Role,
      currentUserRole: getScope(),
      JobStatusCount: [],
      ShortlistStatusCount: [],
    };
  },
  computed: {
    ...mapGetters([
      "getCustomerId",
      "getJobTotalCount",
      "getTotalCount",
      "getDashboardMetrics",
      "getShortliststatus",
      "getInterviewstatus",
      "getRecruitstatus",
      "getFacilityConfig",
      "getshortlistcandidates",
      "getCandidateExpiryCount",
      "getScratchPads",
      "getJobCandidateTabs",
      "getUser",
      "getCandidateByType",
      "getCandidateCount",
      "getJobStatusTabTotalCount",
      "getShortlistStatusCount",
      "getTabTotalCounts",
      "getCandidateByTypeId",
      "getRecruitedCandidateCount",
      "getRegisteredUserCount",
    ]),
    getCandidateData() {
      let getCandidateData =
        this.getCandidateByTypeId &&
        this.getCandidateByTypeId?.summary?.map((data) => {
          const getData = {};
          getData.id = data.candidate_type_id;
          getData.label = data.candidate_type;
          getData.value = data.num_candidates;
          return getData;
        });
      let totalJob = {
        id: 8,
        label: "Total Jobs Posted",
        value: this.getJobTotalCount,
      };
      let totalCandidates = {
        id: 9,
        label: "Total Candidates",
        value: this.getTotalCount,
      };
      let totalRecruited = {
        id: 10,
        label: "Total Recruited",
        value: this.getRecruitedCandidateCount,
      };
      let totalRegistedUser = {
        id: 11,
        label: "Total Registered Users & Admins",
        value: this.getRegisteredUserCount,
      }
      getCandidateData
        ? getCandidateData.unshift(totalJob, totalCandidates, totalRecruited, totalRegistedUser)
        : [];
      let candidateData = _.filter(getCandidateData, (data) => {
        if (data.id != 6 && data.id != 7) return data;
      });
      // let agency = { id: 0, label: "Agency", value: "--" };
      // getCandidateData ? getCandidateData.push(agency) : [];
      const sortOrder = [8, 9, 10, 11, 1, 4, 2, 3];
      return candidateData.sort((a, b) => {
        return sortOrder.indexOf(a.id) - sortOrder.indexOf(b.id);
      });
    },
    getJobStatusCount() {
      this.getJobStatusTabTotalCount
        ? (this.JobStatusCount = [
            {
              displayName: "Draft",
              name: "draft",
              id: 1,
              count: this.getJobStatusTabTotalCount.draft,
              value: this.getJobStatusTabTotalCount.draft,
              urlToJobStatus: "/job-list?published=%5B27%5D",
            },
            {
              displayName: "Active",
              name: "active",
              id: 3,
              count: this.getJobStatusTabTotalCount.active,
              value: this.getJobStatusTabTotalCount.active,
              urlToJobStatus: "/job-list?published=%5B29%5D",
            },
            {
              displayName: "Inactive",
              name: "inactive",
              id: 4,
              count: this.getJobStatusTabTotalCount.inactive,
              value: this.getJobStatusTabTotalCount.inactive,
              urlToJobStatus: "/job-list?published=%5B30%5D",
            },
          ])
        : [];
      return this.JobStatusCount;
    },
    getApplicationStatusCount() {
      this.ShortlistStatusCount = this.getShortlistStatusCount
        ? [
            {
              displayName: "Applied",
              name: "applied",
              id: 4,
              count: this.getShortlistStatusCount.applied,
              value: this.getShortlistStatusCount.applied,
              urlToJobStatus: "/candidates-status?status=applied",
            },
            {
              displayName: "ShortList",
              name: "shortlist",
              id: 1,
              count: this.getShortlistStatusCount.shortlist,
              value: this.getShortlistStatusCount.shortlist,
              urlToJobStatus: "/candidates-status?status=34",
            },
            {
              displayName: "Interview",
              name: "interview",
              id: 2,
              count: this.getShortlistStatusCount.interview,
              value: this.getShortlistStatusCount.interview,
              urlToJobStatus: "/candidates-status?status=36",
            },
            {
              displayName: "Recruit",
              name: "recruit",
              id: 3,
              count: this.getShortlistStatusCount.recruit,
              value: this.getShortlistStatusCount.recruit,
              urlToJobStatus: "/candidates-status?status=37",
            },
            {
              displayName: "Withdrawn",
              name: "withdrawn",
              id: 3,
              count: this.getShortlistStatusCount.withdrawn,
              value: this.getShortlistStatusCount.withdrawn,
              urlToJobStatus: "/candidates-status?status=withdrawn",
            },
          ]
        : [];
      return this.ShortlistStatusCount;
    },
  },
  methods: {
    ...mapActions([
      "fetchJobTotalCount",
      "getTotalCandidates",
      "getallshortliststatus",
      "getScratchPadItems",
      "fetchJobCandidateTabs",
      "jobCandidateTabTotalCount",
      "fetchUser",
      "fetchJobStatusTabTotalCount",
      "getTotalSelectedCandidatesCount",
      "fetchCandidateByTypeId",
      "fetchAppliedCandidatesForJobsCount",
      "fetchWithdrawnCandidatesForJobsCount",
      "fetchRecruitedCandidateCount",
      "fetchRegisteredUserCount",
      "scrollTop",
    ]),
    getTheme(id) {
      let src = "";
      let color = "";
      switch (id) {
        case 0:
          src = "fas fa-clinic-medical";
          color = "#35c792;";
          break;
        case 1:
          src = "fas fa-user-md";
          color = "#8a08a2;";
          break;
        case 2:
          src = "fas fa-user-nurse";
          color = "#1973e8";
          break;
        case 3:
          src = "fas fa-notes-medical";
          color = "#61892F;";
          break;
        case 4:
          src = "fas fa-tooth";
          color = "#e01f66;";
          break;
        case 5:
          src = "fas fa-prescription-bottle-alt";
          color = "#B23850;";
          break;
        case 6:
          src = "fas fa-hospital";
          color = "#895061;";
          break;
        case 8:
          src = "img/job.png";
          color = "#8a08a2;";
          break;
        case 9:
          src = "img/group.png";
          color = "#dd3651;";
          break;
        case 10:
          src = "fas fa-user-tag";
          color = "#1973e8;";
          break;
        case 11:
          src = "fas fa-user-shield";
          color = "#61892F;";
          break;
        default:
          src = "fas fa-user-shield";
          color = "#9117a8;";
      }
      return {
        src: src,
        bg_color: "background-color: " + color,
        color: "color: " + color,
        text_color: "color: " + color,
      };
    },
    routeToCandidateList() {
      this.$router.push(FACILITY_CANDIDATE_URL);
    },
    iconHide(id) {
      if (id == 8 || id == 9) return false;
      return true;
    },
  },
  created() {    
    this.scrollTop();
    let appendAction = [];
    let appendConfigAction;
    if (
      this.getShortliststatus.length == undefined ||
      this.getInterviewstatus.length == undefined ||
      this.getRecruitstatus.length == undefined ||
      !this.getFacilityConfig.length
    ) {
      appendAction = [...appendAction, this.getallshortliststatus()];
    }
    if (!(isObject(this.getUser) && !isEmptyObjectCheck(this.getUser)))
      appendAction = [...appendAction, this.fetchUser()];
    Promise.all(appendAction).then((res) => {
      this.fetchJobTotalCount(this.getCustomerId);
      this.getTotalCandidates(this.getCustomerId);
      // this.$store.dispatch('getShortlistedCandidates')
      this.jobCandidateTabTotalCount(this.getCustomerId);
      this.fetchJobStatusTabTotalCount(this.getCustomerId);
      this.fetchCandidateByTypeId();
      this.fetchAppliedCandidatesForJobsCount();
      this.getTotalSelectedCandidatesCount(34);
      this.getTotalSelectedCandidatesCount(36);
      this.getTotalSelectedCandidatesCount(37);
      this.fetchWithdrawnCandidatesForJobsCount();
      this.fetchRecruitedCandidateCount();
      this.fetchRegisteredUserCount();
    });
  },
};
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 450px) {
  .tile {
    margin-top: 20px;
  }
}
</style>
