<template>
  <div class="calendar-pad">
    <CCard>
      <CCardBody>
        <h5 class="text-primary">Calendar</h5>
        <div class="calendar-wrapper">
          <vue-cal
            class="vuecal--primary-theme"
            :time="false"
            active-view="month"
            :events="events"
            :disable-views="['years', 'year']"
            :on-event-click="onEventClick"
          />
        </div>
      </CCardBody>
    </CCard>
    <CModal class="text-black" :show.sync="showDialog" :close-on-backdrop="false" color="primary">
      <template #header>
        <h6 class="modal-title">Test Title</h6>
        <CButtonClose @click="showDialog=false" class="text-black" />
      </template>
      <template #footer>
        <CButton type="button" color="primary" class="px-4" @click="showDialog=false">Close</CButton>
      </template>
      <p>Testing event</p>
    </CModal>
  </div>
</template>
<script>
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
export default {
  components: {
    VueCal,
  },
  data() {
    return {
      showDialog: false,
      selectedEvent: {},
      events: [
        {
          start: "2020-09-21",
          end: "2020-09-21",
          title: "Need to go shopping",
          content: '<i class="fas fa-birthday-cake">shopping_cart</i>',
          class: "leisure",
        },
        {
          start: "2020-09-21",
          end: "2020-09-21",
          title: "Golf with John",
          content: '<i class="fas fa-user">golf_course</i>',
          class: "sport",
        },
        {
          start: "2020-09-22",
          end: "2020-09-22",
          title: "Dad's birthday!",
          content: '<i class="fas fa-birthday-cake">cake</i>',
          class: "sport",
        },
      ],
    };
  },
  methods: {
    onEventClick(event, e) {
      this.selectedEvent = event;
      this.showDialog = true;
      e.stopPropagation();
    },
  },
};
</script>
<style lang="scss" scoped>
.calendar-pad {
  .calendar-wrapper {
    height: 340px;
  }
}
</style>