<template>
  <div class="candidate-alert">
    <CCard>
      <CCardBody>
        <h5 class="text-primary">Alert 1 - Job and Candidate Status</h5>
        <CTabs variant="tabs" :active-tab="0">
          <CTab title="Job Approval">
            <div class="documentScrolling document-table">
              <table class="table">
                <thead>
                  <tr>
                    <th class="nowrap" v-for="(data,index) in jobFields" :key="'th'+index" style="width:2%">{{data.label}}</th>
                  </tr>
                </thead>
                <tbody v-if="getOnlyJobPendingActions.length">
                  <tr v-for="(prop, rowKey) in getOnlyJobPendingActions" :key="rowKey">
                    <td
                      v-for="(data,columnKey) in prop"
                      :key="columnKey + 'col'"
                      :style="columnKey === 'job_id' ? 'color: #2678ce; font-weight: 600;' : ' '"
                    >{{data}}</td>
                  </tr>
                </tbody>
              </table>
              <div v-if="!getOnlyJobPendingActions.length" class="p-3">
                <h5 class="text-center" :style="'color:'"> No Data Found <i class="fas fa-ban" :style="{'color':'#e55353'}"></i></h5>
              </div>
            </div>
          </CTab>
          <CTab title="Candidate Status">
            <div class="documentScrolling document-table">
              <table class="table">
                <thead>
                  <tr>
                    <th class="nowrap m-5" v-for="(data,index) in candiateFields" :key="'th'+index">{{data.label}}</th>
                  </tr>
                </thead>
                <tbody v-if="getOnlyCandidatePendingActions.length">
                  <tr v-for="(prop, rowKey) in getOnlyCandidatePendingActions" :key="rowKey">
                    <td
                      v-for="(data,columnKey) in prop"
                      :key="columnKey + 'col'"
                      :style="columnKey === 'candidate_id' ? 'color: #2678ce; font-weight: 600;' : ' '"
                    >{{data}}</td>
                  </tr>
                </tbody>
              </table>
              <div v-if="!getOnlyCandidatePendingActions.length" class="p-3">
                <h5 class="text-center" :style="'color:'"> No Data Found <i class="fas fa-ban" :style="{'color':'#e55353'}"></i></h5>
              </div>
            </div>
          </CTab>
        </CTabs>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import _ from 'lodash'
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex';

export default {
  props: ['shortlistcandidates'],
  data() {
    return {
      candiateFields: [
        { label: "Candidate ID", key: "candidate_id" },
        { label: "Candidate Name", key: "candidate_name"},
        { label: "Job Title", key: "job_title"  },
        { label: "Initiated Date", key: "initiated_date"  },
        { label: "Expiry Date", key: "expiry_date"  },
        { label: "Status", key: "status"  },
        { label: "Comments", key: "comments"  }
      ],
      jobFields: [
        { key: "job_id", label: "Job ID" },
        { key: "job_title", label: "Job Tittle" },
        { key: "initiated_date", label: "Initiated Date"},
        { key: "expiry_date", label: "Expiry Date" },
        { key: "status_id", label: "Status" },
        { key: "comments", label: "Comments" },
      ],
    };
  },
  computed: {
    ...mapGetters(['getAllCandidateJobStatus', 'getAllJobsByExpiryDate', 'getActionDetailsByRecruiter']),
    getCandidateActionDetails() {
      return this.getActionDetailsByRecruiter?.length
        ? this.getActionDetailsByRecruiter.map((val) => ({
            candidate_id: val.candidate_uid,
            candidate_name: val.candidate?.full_name,
            job_title: val.job?.job_title,
            initiated_date: val.initiated_date ? moment(val.initiated_date).format('DD-MMM-YYYY') : "--",
            expiry_date: val.expiry_date ? moment(val.expiry_date).format('DD-MMM-YYYY') : "--",
            status: "Pending",
            comments: val.comments,
            completed_by: val.completed_by,
            completed_date: val.completed_date
          }))
        : [];
    },
     getJobActionDetails() {
      return this.getActionDetailsByRecruiter?.length
        ? this.getActionDetailsByRecruiter.map((val) => ({
            job_id: val.job_id,
            job_title: val.job?.job_title,
            initiated_date: val.initiated_date ? moment(val.initiated_date).format('DD-MMM-YYYY') : "--",
            expiry_date: val.expiry_date ? moment(val.expiry_date).format('DD-MMM-YYYY') : "--",
            status: "Pending",
            comments: val.comments,
            completed_by: val.completed_by,
            completed_date: val.completed_date,
            candidate_id: val.candidate_uid,
          }))
        : [];
    },
    getOnlyJobPendingActions() {
      return this.getJobActionDetails
        ? _.filter(this.getJobActionDetails, (action) => {
            if(action?.completed_date === null && action?.completed_by === null && action.candidate_id === null) {
              delete action.completed_by
              delete action.completed_date
              delete action.candidate_id
              return action
            }
        })
        : []
    },
    getOnlyCandidatePendingActions() {
      let actions = {}
      return this.getCandidateActionDetails
        ? _.filter(this.getCandidateActionDetails, (action) => {
            if(action?.completed_date === null && action?.completed_by === null && action.candidate_id !== null) {
              delete action.completed_by
              delete action.completed_date
              return action
            }
        })
        : []
    },
  },
  mounted() {
    this.$store.dispatch('getAllCandidateJobStatus')
    this.$store.dispatch('getRecruiterActionDetails')
  },
  methods: {
    ...mapActions(['getRecruiterActionDetails']),
    countCandidatesByStatus(columnKey, status) {
      return _.sumBy(this.getAllJobsByExpiryDate[columnKey], job => {
        return job.candidate_counts?.[status.toLowerCase()]
      }) || 0
    }
  }
};
</script>
<style lang="scss" scoped>
.nowrap {
  white-space: nowrap;
}
table {
  border: 1px solid #e3e3e3;
}
thead {
  background-color: #fafafa;
}
td {
  color: #777777;
}
</style>