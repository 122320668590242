<template>
  <CRow class="row d-flex justify-content-start align-items-center">
    <CCol class="col-5 p-0 d-flex justify-content-center align-items-center">
      <Pie :PieChartData="PieChartData" />
    </CCol>
    <CCol class="col-7 p-0">
      <PieChartCustomLegend
        :data="PieChartLegendData"
        :isFromJobStatus="isFromJobStatus"
      />
    </CCol>
  </CRow>
</template>

<script>
import Pie from "@/components/reusable/Charts/Pie";
import PieChartCustomLegend from "@/components/reusable/Charts/PieChartCustomLegend";
export default {
  components: {
    Pie,
    PieChartCustomLegend,
  },
  props: {
    PieChartLegendData: {
      type: Array,
      default: [],
    },
    PieChartData: {
      type: Array,
      default: [],
    },
    isFromJobStatus: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
