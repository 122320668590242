<template>
  <div class="scratch-pad">
    <CCard>
      <CCardHeader>
        <div class="card-header-title d-flex align-items-center justify-content-between">
          <h5 class="text-primary">Scratch Pad</h5>
          <div class="d-flex justify-content-start">
            <CButton shape="square" color="primary" class="fs-12" @click="newTodo()">Add</CButton>
          </div>
        </div>
      </CCardHeader>
      <CCardBody class="scroll-area-lg p-0">
        <div class="to-do-content">
          <ul class="list-group list-group-flush">
            <li class="list-group-item" v-for="(data,index) in getScratchPads" :key="'todo'+index">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left mr-2">
                    <div class="custom-checkbox" color="primary">
                      <!-- <input type="checkbox" :id="'todo_input_'+index" class="custom-control-input" /> -->
                      <!-- <label :for="'todo_input_'+index" class="custom-control-label">&nbsp;</label> -->
                    </div>
                  </div>
                  <div class="widget-content-left">
                    <div class="widget-heading">{{ data.title }}</div>
                    <div class="widget-subheading text-muted">
                      <p class="mb-0">
                        {{ data.description }}
                        <small class="opacity-6 ml-1">
                          <i class="fas fa-calendar" />
                          {{ dateFormat(data.date_added).time  }} &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                          {{ dateFormat(data.date_added).date  }}
                        </small>
                      </p>
                    </div>
                  </div>
                  <div class="d-flex widget-content-right widget-content-actions">
                    <CButton class="edit-icon" @click="edit(data)">
                      <i class="fas fa-pencil-alt text-danger" />
                    </CButton>
                    <CButton class="delete-icon" @click="showDeleteConfirmation(data)">
                      <i class="fas fa-trash-alt text-danger" />
                    </CButton>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </CCardBody>
    </CCard>
    <ScratchPadModal
      v-if="currentTodo"
      :todo="currentTodo"
      :isShowPopup="isShowPopup"
      @modalCallBack="scratchModalCallBack"
      @submit="save"
    >
    </ScratchPadModal>
    <CModal
      color="primary"
      :show.sync="delPopup"
      :close-on-backdrop="false"
      :centered="true"
      size="sm"
      >
      <template #header>
        <h6 class="modal-title">Delete Preferences</h6>
        <CButtonClose @click="scratchModalCallBack" class="text-black" />
      </template>
      <template #footer>
        <CButton form="reasons" color="secondary" @click="hideDeleteConfirmation()">Cancel</CButton>
        <CButton form="reasons" color="primary" type="Submit" @click="deleteTodo(delTodo)">Delete</CButton>
      </template>
      <div>
        <h6>Are you sure to delete <br /><strong>{{ delTodo ? delTodo.title : '' }}</strong>?</h6>
      </div>
    </CModal>
  </div>
</template>
<script>
import _ from 'lodash';
import moment from 'moment'
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import ScratchPadModal from '@/containers/Dashboard/RecruiterDashBoard/ScratchPadModal'

export default {
  components: {
    ScratchPadModal
  },
  data() {
    return {
      todo: {},
      isShowPopup: false,
      delPopup: false,
      currentTodo: null,
      delTodo: null
    }
  },
  computed:{
    ...mapGetters([
      'getScratchPads',
      'getScratchPad'
    ]),
  },
  mounted() {
    this.$store.dispatch('getScratchPadItems')
  },
  methods: {
    dateFormat(date) {
      return {
        date: moment(date).format('DD-MM-YYYY'),
        time: moment(date).format('hh:mm A')
      }
    },
    newTodo() {
      this.currentTodo = { title: '', description: ''}
      this.isShowPopup = true
    },
    edit(todo) {
      this.currentTodo = todo
      this.isShowPopup = true
      this.$store.dispatch('setScratchPad', todo)
    },
    scratchModalCallBack(action, data) {
      if (action === false) {
        this.isShowPopup = false;
        this.currentTodo = null
      }
    },
    save(todo) {
      if(todo.id) {
        this.$store.dispatch('putScratchPad', todo)
      } else {
        this.$store.dispatch('postScratchPad', todo).then(() => this.currentTodo = null)
      }
      this.isShowPopup = false
    },
    showDeleteConfirmation(item) {
      this.delTodo = item
      this.delPopup = true
    },
    hideDeleteConfirmation() {
      this.delPopup = false
    },
    deleteTodo(item) {
      this.$store.dispatch('deleteScratchpad', item)
      this.delPopup = false
    }
  }
};
</script>
<style lang="scss" scoped>
$primary: #dd3651;

.fs-12 {
  font-size: 12px;
}
.scroll-area-lg {
  height: 350px;
  overflow-y: auto;
}
.list-group {
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}
.widget-content {
  padding: 1rem;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
}
.widget-content .widget-content-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
  flex: 1;
  position: relative;
  -ms-flex-align: center;
  align-items: center;
}
.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.widget-content .widget-content-left {
  font-weight: 600;
}
.widget-content .widget-content-right {
  margin-left: auto;
}

h6 {
  line-height: 1.4rem;
}
.custom-checkbox {
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  background: $primary;
  padding: 0.25rem
}
// .delete-icon {
//   display: none;
// }
// .edit-icon {
//   display: none;
// }
// .widget-content-wrapper:hover .delete-icon {
//   display: block;
// }
// .widget-content-wrapper:hover .edit-icon {
//   display: block;
// }
</style>