<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      :centered="true"
      size="sm"
      v-if="todosData"
    >
      <template #header>
        <h6 class="modal-title" v-if="!todosData">Edit Preferences</h6>
        <h6 class="modal-title" v-else>Add Preferences</h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
        <CButton type="button" color="primary" class="px-4" @click="onSubmit()">Save</CButton>
      </template>
      <div v-if="todosData">
        <ValidationObserver ref="scratchPad" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)" name="scratchPad">
            <div class="pb-2">
              <label class="required">Title</label>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <TextInput
                  name="title"
                  :value="todosData.title"
                  @change="handleInput"
                  :error="errors[0]"
                />
              </ValidationProvider>
            </div>
            <div class="pb-2">
              <label>Description</label>
              <TextareaInput
                name="description"
                :value="todosData.description"
                @change="handleInput"
              />
            </div>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import TextInput from "@/components/reusable/Fields/TextInput";
import TextareaInput from "@/components/reusable/Fields/TextareaInput";

export default {
  props: {
    todo: {
      type: Object,
      default: {}
    },
    isShowPopup: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TextInput,
    TextareaInput
  },
  data() {
    return {
      todosData: null
    }
  },
  computed: {
    ...mapGetters([
      'getCustomerId',
      'getUserId'
    ])
  },
  watch: {
    todo() {
      this.cloneTodo()
    }
  },
  methods: {
    ...mapActions(['showToast']),
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },
    async onSubmit() {
      this.$refs.scratchPad.validate().then(success => {
        if(!success) {
          this.showToast({
            class: "bg-danger text-white",
            message: "please provide an input!",
          });
          return;
        }
        this.$emit('submit', {
          title: this.todosData.title,
          description: this.todosData.description,
          customer_uid: this.getCustomerId,
          user_id: this.getUserId,
          id: this.todosData.id
        })
      })
    },
    handleInput(name, value) {
      Vue.set(this.todosData, name, value);
    },
    cloneTodo() {
      this.todosData = this.todo ? { ...this.todo } : { title: null, description: null }
    }
  },
  mounted() {
    this.cloneTodo()
  }
}
</script>