<template>
  <div class="job-alert">
    <CCard>
      <CCardBody>
        <h5 class="text-primary">Alert 2 - RAG Status</h5>
        <div class="documentScrolling document-table">
          <table class="table">
            <thead>
              <tr>
                <th class="nowrap" v-for="(data,index) in fields" :key="'th'+index">{{data.label}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(prop, rowKey) in getItems" :key="rowKey" :class="'job-'+getColor()">
                <td
                  v-for="(data,columnKey) in prop"
                  :key="columnKey+'col'"
                  :class="columnKey==='job_id'?'info-text':''"
                >{{data}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
export default {
  data() {
    return {
      fields: [
        { label: "Job ID" },
        { label: "Type" },
        { label: "Speciality" },
        { label: "Facility" },
        { label: "Location" },
        { label: "Aging" },
        { label: "No. of Profile Shortlisted" },
        { label: "No. of Profile Interview" },
      ],
      getItems: [
        {
          job_id: "TFA12256",
          type: "Physician",
          speciality: "General Physician",
          facility: "Test Data",
          location: "IND",
          age: 7,
          no_shortlist: 4,
          no_interview: 2,
        },
        {
          job_id: "TFA12256",
          type: "Physician",
          speciality: "General Physician",
          facility: "Test Data",
          location: "IND",
          age: 7,
          no_shortlist: 4,
          no_interview: 2,
        },
        {
          job_id: "TFA12256",
          type: "Physician",
          speciality: "General Physician",
          facility: "Test Data",
          location: "IND",
          age: 7,
          no_shortlist: 4,
          no_interview: 2,
        },
      ],
    };
  },
  methods: {
    getColor() {
      let colors = ["success", "warning", "danger"];
      return colors[Math.floor(Math.random() * (10 - 1) + 1) % 3];
    },
  },
};
</script>
<style lang="scss" scoped>
.nowrap {
  white-space: nowrap;
}
table {
  border: 1px solid #e3e3e3;
}
thead {
  background-color: #fafafa;
}
td {
  color: #777777;
}
.job-success {
  background-color: #d9f3d8;
}
.job-warning {
  background-color: #fefbda;
}
.job-danger {
  background-color: #fadae5;
}
.info-text {
  color: #2678ce;
  font-weight: 600;
}
</style>