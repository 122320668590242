<template>
  <div>
    <CRow>
      <CCol lg="3">
        <div class="card">
          <div class="card-body d-flex align-items-center p-3">
            <div class="mr-3 text-purple p-3 icon-wrapper">
              <span>
                <img src="img/job.png" />
              </span>
            </div>
            <div>
              <div class="text-value text-purple">{{ jobTotalCount }}</div>
              <div class="text-muted font-weight-bold small">Total Jobs Posted</div>
            </div>
          </div>
        </div>
      </CCol>
      <CCol lg="3">
        <div class="card">
          <div class="card-body d-flex align-items-center p-3">
            <div class="mr-3 text-info p-3 icon-wrapper">
              <span>
                <img src="img/group.png" />
              </span>
            </div>
            <div>
              <div class="text-primary text-value">{{ recruitCount }}</div>
              <div class="text-muted font-weight-bold small">Total Candidates</div>
            </div>
          </div>
        </div>
      </CCol>
      <CCol lg="3">
        <div class="card">
          <div class="card-body d-flex align-items-center p-3">
            <div class="mr-3 text-primary p-3 icon-wrapper">
              <span>
                <img src="img/interview.png" />
              </span>
            </div>
            <div>
              <div class="text-primary text-value">{{ interviewCount }}</div>
              <div class="text-muted font-weight-bold small">Total Profile for Interview</div>
            </div>
          </div>
        </div>
      </CCol>
       <CCol lg="3">
        <div class="card">
          <div class="card-body d-flex align-items-center p-3">
            <div class="mr-3 text-green p-3 icon-wrapper">
              <span>
                <img src="img/shortlist.png" />
              </span>
            </div>
            <div>
              <div class="text-value text-green">{{ shortlistCount}}</div>
              <div class="text-muted font-weight-bold small">Total Profile Shortlisted</div>
            </div>
          </div>
        </div>
      </CCol>
    </CRow>
  </div>
</template>
<script>
export default {
  props: ['jobTotalCount', 'shortlistCount', 'interviewCount', 'recruitCount']
};
</script>
<style lang="scss" scoped>
.text-purple {
  color: #9117a8;
}
.text-green {
  color: #35c792;
}
.icon-wrapper {
  span img {
    width: 45px;
  }
}
.text-value {
  font-size: 2rem;
  font-weight: 600;
}
</style>