<template>
  <div class="pie-legend" v-if="isFromJobStatus">
    <div
      class="legend"
      v-for="legend in data"
      :key="legend.name"
      style="cursor: pointer"
      @click="$router.push(`${legend.urlToJobStatus}`)"
    >
      <p class="legend-count m-0" :style="`color: ${getColor(legend.name)}`">
        {{ legend.count }}
      </p>
      <p class="label">{{ legend.displayName }}</p>
    </div>
  </div>
  <div class="pie-legend" v-else>
    <div class="legend" v-for="legend in data" :key="legend.name">
      <p class="legend-count m-0" :style="`color: ${getColor(legend.name)}`">
        {{ legend.count }}
      </p>
      <!-- {{getColor(legend.name)}} -->
      <p class="label">{{ legend.displayName }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    data: {
      type: Array,
      default: [],
    },
    isFromJobStatus: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["getColor"]),
  },
};
</script>
<style lang="scss" scoped>
.pie-legend {
  display: flex;
  flex-flow: row wrap;
  .legend {
    min-width: 100px;
    width: 20%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 5px 0;
    .legend-count {
      font-weight: 600;
      font-size: x-large;
    }
    .label {
      margin: 0;
    }
  }
}
</style>
